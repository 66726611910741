<script setup>
import { defineProps, computed } from "vue";

const props = defineProps({
  die: Object,
  size: {
    type: String,
    default: "normal",
  },
});

const sizeClass = computed(() => {
  if (props.size === "small") {
    return {
      width: "12",
      iconDefault: "md",
      iconMd: "xl",
      textDefault: "md",
    };
  }
  return {
    width: "20",
    iconDefault: "lg",
    iconMd: "2xl",
    textDefault: "lg",
  };
});
</script>

<template>
  <div
    class="mr-4 mb-4 border rounded-lg flex items-center justify-center border-gray-700 p-4 text-center"
    :class="[
      `die-${die?.preset} w-${sizeClass.width} h-${sizeClass.width} text-${sizeClass.textDefault}`,
    ]"
  >
    <div>
      <div>
        <template v-if="die.type === 'skull'">
          <i
            class="bx bxs-skull"
            :class="[`text-${sizeClass.iconDefault} md:text=${sizeClass.iconMd} mb-0`]"
          ></i>
        </template>
        <template v-else-if="die.type === 'sword'">
          <i
            class="bx bx-knife"
            :class="[`text-${sizeClass.iconDefault} md:text=${sizeClass.iconMd} mb-0`]"
          ></i>
        </template>
        <template v-else>
          <i
            class="bx bxs-shield"
            :class="[`text-${sizeClass.iconDefault} md:text=${sizeClass.iconMd} mb-0`]"
          ></i>
        </template>
      </div>
      <div>{{ die?.value }}</div>
    </div>
  </div>
</template>
