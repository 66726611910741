import { shuffle, random } from "lodash-es";
import DieFace from "./DieFace";

class Die {
  // types: easy, intermediate, hard
  preset = null;
  topindexNumber = 0;
  id = null;
  override = null;
  faceSet = [];

  constructor(data) {
    let faces = [];
    if (["easy", "intermediate", "hard", "tricky"].includes(data)) {
      this.preset = data;
    } else if (["easy", "intermediate", "hard", "tricky"].includes(data?.preset)) {
      this.preset = data.preset;
    } else {
      faces = data?.faces;
    }

    this.id = data?.id ?? random(1, 1000000, false);

    if (this.preset === "intermediate") {
      this.faceSet = [
        new DieFace({ type: "sword", value: 1 }),
        new DieFace({ type: "sword", value: 1 }),
        new DieFace({ type: "skull", value: 1 }),
        new DieFace({ type: "skull", value: 1 }),
        new DieFace({ type: "shield" }),
        new DieFace({ type: "shield" }),
      ];
    } else if (this.preset === "hard") {
      this.faceSet = [
        new DieFace({ type: "sword", value: 1 }),
        new DieFace({ type: "skull", value: 1 }),
        new DieFace({ type: "skull", value: 1 }),
        new DieFace({ type: "skull", value: 1 }),
        new DieFace({ type: "shield" }),
        new DieFace({ type: "shield" }),
      ];
    } else if (this.preset === "tricky") {
      this.faceSet = [
        new DieFace({ type: "sword", value: 4 }),
        new DieFace({ type: "sword", value: 3 }),
        new DieFace({ type: "skull", value: 3 }),
        new DieFace({ type: "skull", value: 2 }),
        new DieFace({ type: "shield" }),
        new DieFace({ type: "shield" }),
      ];
    } else if (this.preset === "easy") {
      // "easy" is the default
      this.faceSet = [
        new DieFace({ type: "sword", value: 1 }),
        new DieFace({ type: "sword", value: 1 }),
        new DieFace({ type: "sword", value: 1 }),
        new DieFace({ type: "skull", value: 1 }),
        new DieFace({ type: "shield" }),
        new DieFace({ type: "shield" }),
      ];
    } else {
      this.preset = "custom";
      this.faceSet = faces?.map((f) => new DieFace(f));
    }

    this.roll();
    // this.topindex = random(0, this.faces.length - 1);
  }

  roll() {
    // this.topface = this.faces[random(0, 5)];
    this.topindexNumber = random(0, this.faces.length - 1);
  }

  setOverride(dieface) {
    this.override = dieface;
  }

  get topindex() {
    if (this.override) {
      return 0;
    }

    return this.topindexNumber;
  }

  get faces() {
    if (this.override) {
      return [this.override, ...this.faceSet.slice(1)];
    }

    return this.faceSet;
  }

  get faceIndex() {
    return this.topindex + 1;
  }

  get topface() {
    return this.faces?.[this.topindex];
  }

  get value() {
    return this.topface?.value;
  }

  get type() {
    return this.topface?.type;
  }
}
export default Die;
