<script setup>
import { random } from "lodash-es";
import { defineProps, computed, onMounted, ref } from "vue";
import DieRolling from "./DieRolling.vue";

const props = defineProps({
  dice: Array,
  rolls: { type: Number, default: 1 },
});

const datrollCountMod5 = computed(() => {
  return props.rolls % 5;
});

const diffNumber = ref(null);

onMounted(() => {
  if (props.rolls > 5) {
    diffNumber.value = random(1, 5);
  } else {
    diffNumber.value = random(6, 100);
  }

  setTimeout(() => {
    diffNumber.value = null;
  }, 100);
});
</script>

<template>
  <div class="dicetable">
    <div class="dicearea">
      <section
        class="dicescene"
        :data-roll-count-mod5="datrollCountMod5"
        data-number-style="dots"
        :data-dice-num="dice?.length + 1"
      >
        <template v-for="(die, d) in dice" :key="d">
          <Transition>
            <DieRolling :die="die" />
          </Transition>
        </template>
      </section>
    </div>
  </div>
</template>

<style lang="pcss" scoped>
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
/*
 * The full-height area for the 3D scene to be centred within
 */
.dicearea {
	display: flex;

}

/*
 * The 3D area
 */
.dicescene {
	perspective: max(70vw, 70vh);
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	user-select: none;
	pointer-events: none;
	margin: auto;

	/* Padding on each side of a die, as a fraction of the --dieEdge */
	--diePad: 0.3;

	/* Helpers */
	--minAxis: min(50vw, 50vh);
	--maxAxis: max(50vw, 50vh);

	/* Space available including padding for each die */
	--maxDieSpace: var(--minAxis);
	--idealDieSpace: calc(100vw);
	--dieSpace: min(var(--maxDieSpace), var(--idealDieSpace));

	/* Side length of die edges */
	--dieEdge: calc(var(--dieSpace) / (1 + 2 * var(--diePad)));

	/* Transforms to either push or pull by half an edge length */
	--pullToSurface: translateZ(calc(var(--dieEdge) / 2));
	--pushToFloor: translateZ(calc(-1 * var(--dieEdge) / 2));
}
.dicescene[data-dice-num="1"] { --idealDieSpace: max(100vw, 100vh); }
.dicescene[data-dice-num="2"] { --idealDieSpace: calc(var(--maxAxis) / 2); }
.dicescene[data-dice-num="3"] { --idealDieSpace: calc(var(--minAxis) / 2); }
.dicescene[data-dice-num="4"] { --idealDieSpace: calc(var(--minAxis) / 2); }
.dicescene[data-dice-num="5"] { --idealDieSpace: calc(var(--maxAxis) / 3); }
.dicescene[data-dice-num="6"] { --idealDieSpace: calc(var(--maxAxis) / 3); }

@media (min-aspect-ratio: 3 / 2), (max-aspect-ratio: 2 / 3) {
	.dicescene[data-dice-num="3"] { --idealDieSpace: calc(var(--maxAxis) / 3); }
	.dicescene[data-dice-num="5"] { --idealDieSpace: calc(var(--minAxis) / 2); }
	.dicescene[data-dice-num="6"] { --idealDieSpace: calc(var(--minAxis) / 2); }
}
@media (min-aspect-ratio: 5 / 2), (max-aspect-ratio: 2 / 5) {
	.dicescene[data-dice-num="5"] { --idealDieSpace: calc(var(--maxAxis) / 5); }
}
@media (min-aspect-ratio: 2), (max-aspect-ratio: 1 / 2) {
	.dicescene[data-dice-num="4"] { --idealDieSpace: calc(var(--maxAxis) / 4); }
}
@media (min-aspect-ratio: 3), (max-aspect-ratio: 1 / 3) {
	.dicescene[data-dice-num="6"] { --idealDieSpace: calc(var(--maxAxis) / 6); }
}


/*
 * On each successive roll, the dice are rotated a pseudo-random number of full
 * turns so it looks more like they're tumbling randomly rather than just going
 * directly to their target face
 *
 * This was generated with the script util/spins.js
 */
.dicescene[data-roll-count-mod5="0"] > :nth-child(6n+1) { --spin: rotateX(0turn) rotateY(0turn) rotateZ(0turn); }
.dicescene[data-roll-count-mod5="1"] > :nth-child(6n+1) { --spin: rotateX(2turn) rotateY(1turn) rotateZ(-2turn); }
.dicescene[data-roll-count-mod5="2"] > :nth-child(6n+1) { --spin: rotateX(-1turn) rotateY(2turn) rotateZ(-3turn); }
.dicescene[data-roll-count-mod5="3"] > :nth-child(6n+1) { --spin: rotateX(-3turn) rotateY(1turn) rotateZ(-1turn); }
.dicescene[data-roll-count-mod5="4"] > :nth-child(6n+1) { --spin: rotateX(-1turn) rotateY(-1turn) rotateZ(-2turn); }
.dicescene[data-roll-count-mod5="0"] > :nth-child(6n+2) { --spin: rotateX(0turn) rotateY(0turn) rotateZ(0turn); }
.dicescene[data-roll-count-mod5="1"] > :nth-child(6n+2) { --spin: rotateX(-2turn) rotateY(2turn) rotateZ(-1turn); }
.dicescene[data-roll-count-mod5="2"] > :nth-child(6n+2) { --spin: rotateX(0turn) rotateY(3turn) rotateZ(0turn); }
.dicescene[data-roll-count-mod5="3"] > :nth-child(6n+2) { --spin: rotateX(2turn) rotateY(2turn) rotateZ(1turn); }
.dicescene[data-roll-count-mod5="4"] > :nth-child(6n+2) { --spin: rotateX(-1turn) rotateY(1turn) rotateZ(2turn); }
.dicescene[data-roll-count-mod5="0"] > :nth-child(6n+3) { --spin: rotateX(0turn) rotateY(0turn) rotateZ(0turn); }
.dicescene[data-roll-count-mod5="1"] > :nth-child(6n+3) { --spin: rotateX(3turn) rotateY(1turn) rotateZ(-1turn); }
.dicescene[data-roll-count-mod5="2"] > :nth-child(6n+3) { --spin: rotateX(0turn) rotateY(2turn) rotateZ(0turn); }
.dicescene[data-roll-count-mod5="3"] > :nth-child(6n+3) { --spin: rotateX(2turn) rotateY(3turn) rotateZ(-2turn); }
.dicescene[data-roll-count-mod5="4"] > :nth-child(6n+3) { --spin: rotateX(0turn) rotateY(1turn) rotateZ(-3turn); }
.dicescene[data-roll-count-mod5="0"] > :nth-child(6n+4) { --spin: rotateX(0turn) rotateY(0turn) rotateZ(0turn); }
.dicescene[data-roll-count-mod5="1"] > :nth-child(6n+4) { --spin: rotateX(2turn) rotateY(-1turn) rotateZ(1turn); }
.dicescene[data-roll-count-mod5="2"] > :nth-child(6n+4) { --spin: rotateX(5turn) rotateY(0turn) rotateZ(0turn); }
.dicescene[data-roll-count-mod5="3"] > :nth-child(6n+4) { --spin: rotateX(2turn) rotateY(-1turn) rotateZ(1turn); }
.dicescene[data-roll-count-mod5="4"] > :nth-child(6n+4) { --spin: rotateX(0turn) rotateY(0turn) rotateZ(2turn); }
.dicescene[data-roll-count-mod5="0"] > :nth-child(6n+5) { --spin: rotateX(0turn) rotateY(0turn) rotateZ(0turn); }
.dicescene[data-roll-count-mod5="1"] > :nth-child(6n+5) { --spin: rotateX(2turn) rotateY(1turn) rotateZ(-2turn); }
.dicescene[data-roll-count-mod5="2"] > :nth-child(6n+5) { --spin: rotateX(0turn) rotateY(3turn) rotateZ(-1turn); }
.dicescene[data-roll-count-mod5="3"] > :nth-child(6n+5) { --spin: rotateX(-2turn) rotateY(1turn) rotateZ(0turn); }
.dicescene[data-roll-count-mod5="4"] > :nth-child(6n+5) { --spin: rotateX(0turn) rotateY(2turn) rotateZ(2turn); }
.dicescene[data-roll-count-mod5="0"] > :nth-child(6n+6) { --spin: rotateX(0turn) rotateY(0turn) rotateZ(0turn); }
.dicescene[data-roll-count-mod5="1"] > :nth-child(6n+6) { --spin: rotateX(-2turn) rotateY(2turn) rotateZ(1turn); }
.dicescene[data-roll-count-mod5="2"] > :nth-child(6n+6) { --spin: rotateX(1turn) rotateY(1turn) rotateZ(0turn); }
.dicescene[data-roll-count-mod5="3"] > :nth-child(6n+6) { --spin: rotateX(-1turn) rotateY(0turn) rotateZ(2turn); }
.dicescene[data-roll-count-mod5="4"] > :nth-child(6n+6) { --spin: rotateX(2turn) rotateY(1turn) rotateZ(1turn); }
</style>
