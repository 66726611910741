<script setup>
import { ref, computed } from "vue";
import Adventure from "@/classes/Dice/Adventure";
import TableDiceRolling from "@/components/Dice/TableDiceRolling.vue";
import DieStatic from "@/components/Dice/DieStatic.vue";
import Rules from "@/components/Dice/Rules.vue";
import NavigationNew from "@/components/NavigationNew.vue";
import store from "@/store";

// every day you pick an adventure
// each adventure has several "levels"
// you have 30 rounds of rolls to try to get through all the levels
// each level has an increasing multiplier on your total score
// every time you beat a level, the next round is at the next level
// if you fail a level, you drop back down to previous level
// the final level you are on determines your final multiplier

const dangerousDice = [
  "easy",
  "easy",
  "easy",
  "intermediate",
  "intermediate",
  "intermediate",
  "intermediate",
  "intermediate",
  "intermediate",
  "intermediate",
  "intermediate",
  "hard",
  "hard",
  "hard",
  "hard",
];

const treacherousDice = [
  "easy",
  "easy",
  "easy",
  "intermediate",
  "intermediate",
  "intermediate",
  "hard",
  "hard",
  "hard",
  "tricky",
  "tricky",
  "tricky",
  "tricky",
];

const generateAdventure = (data) => {
  let adv = new Adventure({ ...data });

  console.info({ adv });

  return adv;
};

// Adventure: constructor({ stages = [], diceset = [], startingCrew = 100, name, description }) {
// Stage: constructor({ id, obstacles = 1, multiplier = 1, reward = 100, name = null, diceset = [] }) {

// Dangerous
// skull runs (40 obstacles): 33, 40, 43, 37, 40, 38, 39 [38 avg]

// Treacherous
// skull runs (40 obstacles): 49, 27, 34, 50, 34, 28, 31 [36 avg]

// Default
// skull runs (35 obstacles): 30, 32, 23, 19, 31, 30, 18 [26.14 svg]
// skull runs (25 obstacles): 20, 13, 15, 12, 20, 20, 17 [13.86 avg]
// skull runs (15 obstacles): 11, 14, 6, 18, 12, 4, 13 [11.14 avg]

const adventures = [
  generateAdventure({
    name: "Island Getaway",
    description: "",
    startingCrew: 25,
    stages: [
      { name: "The Beach", obstacles: 3, multiplier: 0, reward: 50 },
      { name: "Waterfall Pass", obstacles: 5, multiplier: 1, reward: 100 },
      { name: "Secret Cove", obstacles: 7, multiplier: 1, reward: 140 },
      { name: "Bootlegger Bay", obstacles: 10, multiplier: 1, reward: 180 },
      {
        name: "Treacherous Jungle",
        obstacles: 13,
        multiplier: 5,
        diceset: treacherousDice,
        reward: 220,
      },
      {
        name: "Spanish Fortress",
        obstacles: 16,
        multiplier: 10,
        diceset: dangerousDice,
        reward: 260,
      },
    ],
  }),
];

// Adventure runs
// from left
// Rewards: 626 (585 x1.07), Adventure complete: 80%
// Rewards: 626 (585 x1.07), Adventure complete: 80%
// Rewards: 393 (385 x1.02), Adventure complete: 60%
// Rewards: 626 (585 x1.07), Adventure complete: 80%
// Rewards: 393 (385 x1.02), Adventure complete: 60%

// from right
// Rewards: 518 (450 x1.15), Adventure complete: 40%
// Rewards: 518 (450 x1.15), Adventure complete: 40%

const adventure = ref(null);
const setAdventure = (adv) => {
  window.adventure = adv;
  adventure.value = adv;
};

const showRules = computed(() => {
  return store.state.gameDice.showRules;
});

const setShowRules = () => {
  store.dispatch("gameDice/setShowRules", true);
};

const showPreviousDice = ref(false);
const setShowPreviousDice = (v) => {
  showPreviousDice.value = v;
};
</script>

<template>
  <NavigationNew />
  <div class="bg-gray-800 p-4">
    <div class="container">
      <div class="flex justify-between items-center">
        <div></div>
        <div>
          <a href="#" @click="setShowRules" class="cursor-pointer underline text-white text-xs">
            show instructions
          </a>
        </div>
      </div>
    </div>
  </div>
  <div class="px-4 py-12 relative container">
    <div v-if="adventure">
      <div class="flex flex-col md:flex-row items-center w-full">
        <!-- TITLE -->
        <div class="mr-8">
          <h1 class="text-3xl font-ch1script pb-6">{{ adventure.name }}</h1>
        </div>
        <!-- OVERVIEW INFORMATION -->
        <div
          class="flex flex-grow items-center justify-between p-4 bg-gray-200 rounded mb-8 w-full"
        >
          <div>
            <span> Crew: {{ adventure.remainingCrew }} </span><br />
            <span class="text-xs">
              Starting: {{ adventure.startingCrew }}, eliminated:
              {{ adventure.eliminatedCrew }}
            </span>
          </div>
          <div class="text-right">
            <span>
              Rewards: {{ adventure.totalRewards }} ({{ adventure.rewards }} x{{
                adventure.multiplier
              }}) </span
            ><br />
            <span class="text-xs">Adventure complete: {{ adventure.percentWon }}%</span>
          </div>
        </div>
      </div>

      <!-- CURRENT STAGE -->
      <div v-if="adventure.currentStage">
        <div
          class="flex flex-col md:flex-row items-center justify-center md:justify-between p-4 mb-6 bg-ch1cream-light rounded"
        >
          <div>
            <div>
              <h3 class="font-display text-2xl mb-1 text-center md:text-left">
                {{ adventure.currentStage.name }}
                <span
                  class="text-green-700 font-bold text-lg ml-2"
                  v-if="adventure.currentStage.isWon"
                >
                  Complete!
                </span>
              </h3>
              <div class="mb-1 text-xs italic">
                Obstacles: {{ adventure.currentStage.obstacles }}
              </div>
              <div class="text-xs italic">
                Reward: {{ adventure.currentStage.rewardNumber }} doubloons / Adv. bonus
                {{ adventure.currentStage.multiplierNumber }}%
              </div>
            </div>
          </div>
          <div class="text-center md:text-right md:w-1/4">
            <div class="font-bold text-sm mb-2">Dice bag</div>
            <div class="flex flex-wrap justify-center md:justify-end">
              <div
                v-for="(die, d) in adventure.currentStage.diceset"
                :key="d"
                class="w-2 h-2 md:w-4 md:h-4 ml-2 mb-2 md:ml-3 md:mb-4 rounded overflow-hidden"
                :class="[{ 'border border-gray-200': !adventure.currentStage.diceInBag(die) }]"
                :title="`Die type: ${die.preset}`"
              >
                <div
                  :class="[
                    `die-${die.preset} w-full h-full`,
                    { 'opacity-20 ': !adventure.currentStage.diceInBag(die) },
                  ]"
                ></div>
              </div>
            </div>
          </div>
        </div>

        <div class="flex flex-col">
          <div class="flex-grow mb-4">
            <div class="p-4 bg-ch1cream-light rounded">
              <div
                class="mb-4 flex flex-col md:flex-row items-center justify-center md:justify-between"
              >
                <div class="font-bold text-center mb-2 md:text-left">
                  Obstacles remaining: {{ adventure.currentStage.remainingObstacles }}
                </div>
                <div class="font-bold text-center md:text-right">
                  Crew remaining: {{ adventure.remainingCrew }}<br />
                  <span class="text-xs font-normal"
                    >Eliminated here: {{ adventure.currentStage.skulls }}</span
                  >
                </div>
              </div>

              <div class="flex flex-col md:flex-row items-center justify-between">
                <div>
                  <template v-if="!adventure.currentStage.isWon">
                    <button
                      @click="adventure.roll()"
                      class="btn mb-4 md:mb-0"
                      :disabled="!adventure.canRoll"
                    >
                      Roll {{ adventure.currentStage.rolls.length ? "Again" : null }}
                    </button>
                    <template v-if="adventure.remainingCrew === 0">
                      <br />
                      <span class="text-red-600">No more crew members!</span>
                    </template>
                  </template>
                </div>
                <div>
                  <button @click="adventure.leaveStage()" class="btn btn-ch1lightblue">
                    Leave stage
                  </button>
                </div>
              </div>

              <div>
                <div
                  class="w-full text-center flex flex-col justify-center items-center mt-4"
                  v-if="adventure.currentStage?.table?.length"
                >
                  <div class="uppercase font-bold text-gray-400">Current Roll</div>

                  <TableDiceRolling
                    :dice="adventure.currentStage?.table"
                    :rolls="adventure.currentStage.rolls?.length ?? 0"
                  />
                </div>

                <div
                  class="w-full text-center flex flex-col justify-center items-center mt-4"
                  v-if="adventure.currentStage?.scoredNoTable?.length"
                >
                  <div
                    class="font-bold text-gray-400 mb-4 cursor-pointer underline"
                    @click="setShowPreviousDice(!showPreviousDice)"
                  >
                    {{ showPreviousDice ? "Hide" : "Show" }} previously scored dice
                  </div>
                  <div class="flex flex-wrap items-center justify-center" v-if="showPreviousDice">
                    <DieStatic
                      v-for="(die, d) in adventure.currentStage.scoredNoTable"
                      :key="d"
                      :die="die"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="w-full">
            <div class="">
              <div class="mb-4">
                <h3 class="font-display text-xl text-center md:text-left">
                  Perks
                  <!-- <span class="ml-2 underline text-gray-400 font-sans text-xs">show all</span> -->
                </h3>
              </div>
              <div class="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-6 gap-4">
                <div
                  v-for="(perk, p) in adventure.perks"
                  class="p-4 bg-ch1cream-light mb-4 rounded overflow-hidden flex flex-col justify-between"
                  :key="p"
                >
                  <div class="mb-4">
                    <div class="text-ch1blue-light uppercase text-sm font-black">
                      {{ perk.name }}
                    </div>
                    <div
                      class="text-xs mb-2 font-bold"
                      :class="{
                        'text-red-500': (adventure.perksUsed[perk.id] ?? 0) === perk.uses,
                        'text-green-500': (adventure.perksUsed[perk.id] ?? 0) < perk.uses,
                      }"
                    >
                      Used: {{ adventure.perksUsed[perk.id] ?? 0 }} / {{ perk.uses }}
                    </div>
                    <div class="text-xs mb-2">{{ perk.effect }}</div>
                    <div class="text-xs italic text-gray-500 mb-2">
                      Requirement: {{ perk.requirement }}
                    </div>
                  </div>
                  <div>
                    <div>
                      <button
                        :disabled="!perk.canUse(adventure)"
                        @click="perk.activate(adventure)"
                        class="btn btn-sm"
                      >
                        Use
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- STAGE LIST -->
      <div v-else>
        <h3 class="font-display text-2xl mb-6">Stages</h3>
        <div class="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-6 gap-4">
          <div
            v-for="(stage, s) in adventure.stages"
            :key="s"
            class="bg-ch1cream rounded-lg shadow-md p-4"
          >
            <div class="h-full flex flex-col justify-between">
              <div class="mb-4">
                <div class="font-display mb-2">
                  {{ stage.name }}
                </div>
                <div class="mb-2 text-xs italic" v-if="stage.description">
                  {{ stage.description }}
                </div>
                <div class="mb-2 text-xs italic">Obstacles: {{ stage.obstacles }}</div>
                <div class="mb-2 text-xs italic">Reward: {{ stage.rewardNumber }} doubloons</div>
                <div class="mb-2 text-xs italic">
                  Bonus multiplier earned: {{ stage.multiplierNumber }}%
                </div>
                <div class="font-bold text-green-700" v-if="stage.isWon">Complete!</div>
              </div>
              <div>
                <button @click="adventure.enterStage(stage)" class="btn">
                  {{ stage.isWon ? "Review" : "Enter" }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="flex p-8 items-center justify-center">
      <button class="btn" @click="setAdventure(adventures[0])">Start</button>
    </div>
  </div>
  <Rules v-if="showRules" />
</template>

<style lang="pcss">
.die-easy {
  @apply text-white border-green-700 bg-green-700;
}
.die-intermediate {
  @apply text-white border-yellow-500 bg-yellow-500;
}
.die-hard {
  @apply text-white border-red-600 bg-red-600;
}
.die-tricky {
  @apply text-white bg-sothpurple border-sothpurple;
}
.die-custom {
  @apply text-white bg-gray-600 border-gray-800;
}
</style>
