import Die from "./Die";
import Stage from "./Stage";
import { shuffle, random } from "lodash-es";
import DieFace from "./DieFace";

// AVAILABLE TO EVERYBODY, USE 1 PER DAY

// match3skull: match 3 skulls - convert to 1 skull
// match3sword: match 3 swords - convert to 6 swords
// match3shield: match 3 shields - convert to 3 swords
// match1each: match 1 of each - convert to 3 swords
// reroll1random: 1 random die re-roll
// reroll2random: 2 random die re-roll
// rerollall: all die re-roll
// rerollskulls: re-roll all skulls
// reroll1skull: re-roll 1 skull
//

const matchCount = (dice, type) => {
  return dice?.filter((d) => d.type === type)?.length ?? 0;
};

const matchAllType = (dice, type) => {
  return dice?.length && dice.length == matchCount(dice, type);
};

const selectDiceIndexesFromSet = (dice, number, constrainTypes = []) => {
  if (!dice?.length > 0) {
    return [];
  }

  // create array of indexes of dice, excluding any types contrained
  let newArr = [];
  for (let i = 0; i < dice.length; i++) {
    if (!constrainTypes.length || constrainTypes.includes(dice[i].type)) {
      newArr = [...newArr, i];
    }
  }

  // shuffle those indexes - picking random below
  shuffle(newArr);

  // either pick all, or pick the number passed in
  let numberToPick = number ?? newArr.length;

  let diceSelected = [];

  for (let g = 0; g < numberToPick; g++) {
    diceSelected[g] = dice[newArr[g]];
  }

  return diceSelected;
};

const rerollStageTable = (stage, number = null, constrainTypes = []) => {
  // // create array of indexes of table, excluding any types contrained
  // let newArr = [];
  // for (let i = 0; i < stage.table.length; i++) {
  //   if (!constrainTypes.length || constrainTypes.includes(stage.table[i].type)) {
  //     newArr = [...newArr, i];
  //   }
  // }

  // // shuffle those indexes - pickig random below
  // shuffle(newArr);

  // // either pick all, or pick the number passed in
  // let numberToPick = number ?? stage.table.length;

  // let diceToReroll = [];

  // for (let g = 0; g < numberToPick; g++) {
  //   diceToReroll[g] = stage.table[newArr[g]];
  // }
  let diceToReroll = selectDiceIndexesFromSet(stage.table, number, constrainTypes);

  console.info({ diceToReroll });

  diceToReroll.forEach((die) => {
    die.roll();
  });

  return stage.table;
};

const overrideDie = (die, type, value) => {
  die.setOverride(new DieFace({ type, value }));
};

const overrideStageDice = (stage, type, value, number, constrainTypes = []) => {
  let diceToOverride = selectDiceIndexesFromSet(stage.table, number, constrainTypes);

  diceToOverride.forEach((die) => {
    overrideDie(die, type, value);
  });

  return stage.table;
};

class Perk {
  id;
  name;

  uses = 1;
  type = null;

  static presets = {
    matchAllSkulls: {
      name: "Skullmaster",
      requirementDescription: "Roll all skulls",
      effectDescription: "Change value of each skull to '0'",
      uses: 1,
      activate: (stage) => {
        return overrideStageDice(stage, "skull", 0, null, ["skull"]);
      },
      canUse: (dice) => {
        return matchAllType(dice, "skull");
      },
    },
    matchAllSwords: {
      name: "Swordsman",
      requirementDescription: "Roll all swords",
      effectDescription: "Change value of each sword to '2'",
      uses: 1,
      activate: (stage) => {
        return overrideStageDice(stage, "sword", 2, null, ["sword"]);
      },
      canUse: (dice) => {
        return matchAllType(dice, "sword");
      },
    },
    matchAllShields: {
      name: "Defender",
      requirementDescription: "Roll all shields",
      effectDescription: "Convert each shield to a sword with value '1'",
      uses: 1,
      activate: (stage) => {
        return overrideStageDice(stage, "sword", 1, null, ["shield"]);
      },
      canUse: (dice) => {
        return matchAllType(dice, "shield");
      },
    },
    matchEachType: {
      name: "Variety Pack",
      requirementDescription: "Roll 1 sword, 1 shield, and 1 skull",
      effectDescription: "Convert each die to a sword with value '1'",
      uses: 1,
      activate: (stage) => {
        return overrideStageDice(stage, "sword", 1);
      },
      canUse: (dice) => {
        return (
          matchCount(dice, "skull") > 0 &&
          matchCount(dice, "sword") > 0 &&
          matchCount(dice, "shield") > 0
        );
      },
    },
    rerollOneRandom: {
      name: "Single flip",
      requirementDescription: "None",
      effectDescription: "A single random die is re-rolled",
      uses: 1,
      activate: (stage) => {
        return rerollStageTable(stage, 1);
      },
      canUse: (dice) => {
        return !!dice?.length;
      },
    },
    rerollTwoRandom: {
      name: "Double flip",
      requirementDescription: "None",
      effectDescription: "2 random dice are re-rolled",
      uses: 1,
      activate: (stage) => {
        return rerollStageTable(stage, 2);
      },
      canUse: (dice) => {
        return !!dice?.length;
      },
    },
    rerollAll: {
      name: "Do-over",
      requirementDescription: "None",
      effectDescription: "All dice are re-rolled",
      uses: 1,
      activate: (stage) => {
        return rerollStageTable(stage);
      },
      canUse: (dice) => {
        return !!dice?.length;
      },
    },
    rerollSkulls: {
      name: "Pain Killer",
      requirementDescription: "Roll at least 1 skull",
      effectDescription: "Each die with a skull is re-rolled",
      uses: 1,
      activate: (stage) => {
        return rerollStageTable(stage, null, ["skull"]);
      },
      canUse: (dice) => {
        return matchCount(dice, "skull") > 0;
      },
    },
    rerollOneSkull: {
      name: "Singe Pain",
      requirementDescription: "Roll at least 1 skull",
      effectDescription: "A single random die with a skull is re-rolled",
      uses: 1,
      activate: (stage) => {
        return rerollStageTable(stage, 1, ["skull"]);
      },
      canUse: (dice) => {
        return matchCount(dice, "skull") > 0;
      },
    },
  };

  // type is required or nothing would work, everything else is just data
  constructor({ type, id = null, name = null, description = null, uses = 1 }) {
    this.id = id ?? random(1, 1000000, false);
    this.type = type;

    this.name = name ?? this.preset.name;
    this.uses = uses ?? this.preset.uses;
  }

  static defaultPerkset() {
    return Object.keys(Perk.presets);
  }

  get preset() {
    return Perk.presets[this.type];
  }

  get requirement() {
    return this.preset.requirementDescription;
  }

  get effect() {
    return this.preset.effectDescription;
  }

  // we activate perks against a stage
  activate(adventure) {
    if (this.canUse(adventure)) {
      let resultDice = this.preset.activate(adventure.currentStage);
      adventure.currentStage.addRollLog(resultDice, this);
    }
  }

  // we will check against the dice
  // we will also check against the adventure perksUsed
  canUse(adventure) {
    if (
      adventure.currentStage &&
      !adventure.currentStage.isWon &&
      adventure.perksUsed[this.id] < this.uses
    ) {
      return this.preset.canUse(adventure.currentStage.table);
    }

    return false;
  }
}

export default Perk;
