class DieFace {
  // Pirate attack: you are an invader are trying to overtake a ship, steal as much booty as you can, don't lose your whole crew

  // types: treasure (you steal a chest of booty), skull (a crewmate is taken out), impasse (no kill or theft)
  // type will be based on value; negative is skull, positive is treasure, impasse is 0
  // value: how much treasure, how many crewmates kills, how many impasses
  type = "shield";
  valueNumber = 0;

  // is there something this dice face does?
  // maybe this is just the "type"
  effect = null;

  constructor({ type, value = 0 }) {
    this.type = ["shield", "sword", "skull"].includes(type) ? type : "shield";
    this.valueNumber = value;
  }

  get value() {
    if (this.type === "shield") {
      return 0;
    }

    return this.valueNumber;
  }
}

export default DieFace;
