<script setup>
import Fixed from "../Fixed.vue";
import Overlay from "../Overlay.vue";
import Modal from "../Poker/Modal.vue";
import store from "@/store";
import { ref } from "vue";
import { DateTime } from "luxon";

const leaderboardData = ref(null);
const loading = ref(false);
const error = ref(null);

const hideRules = () => {
  store.dispatch("gameDice/setShowRules", false);
};
const nothing = () => {
  //  shouldn't hide
};
const formatDate = (date) => {
  return DateTime.fromISO(date).toLocaleString(DateTime.DATE_MED);
};
</script>

<template>
  <Fixed>
    <Overlay @click="hideRules">
      <Modal @click.stop="nothing">
        <div class="leaderboard-modal max-w-full">
          <div
            class="w-full flex flex-col lg:flex-row justify-between, items-start lg:items-center"
          >
            <div class="flex mb-4 lg:mb-0 mt-4">
              <div class="font-ch1script text-6xl text-ch1blue">Rules</div>
            </div>
            <div class="flex flex-grow md:justify-end flex-wrap md:flex-nowrap"></div>
          </div>
          <div class="my-6 text-left overflow-auto w-full">
            <template v-if="loading">
              <div>Loading...</div>
            </template>
            <template v-else-if="error"> </template>
            <template v-else>
              <div class="w-full">
                <p>
                  The object of the game is to see how much treasure you can bring back before
                  running out of crewmates.
                </p>
                <p>
                  On each turn, you are rolling 3 virtual 6-sided dice from a bag filled with
                  different kinds of dice: easy (green), average (yellow), hard (red), or tricky
                  (purple). The dice selected out of the bag are random - you only know what you
                  have already pulled and what is left in the bag.
                </p>
                <p>
                  When you start the game, you can select a stage. When you enter a stage, you can
                  see how many obstacles you will need to eliminate before being rewarded with
                  treasure and the types of dice in the bag for that stage.
                </p>
                <p>Rolling will give you a chance to eliminate obstacles or lose crew members.</p>
                <p>The possible dice faces and their meanings are:</p>
                <ul>
                  <li><i class="bx bx-xs bx-knife"></i>: You eliminated an obstacle</li>
                  <li>
                    <i class="bx bx-xs bxs-shield"></i>: You didn't eliminate an obstacle, but your
                    crew held strong (this die will get re-rolled if you roll again!)
                  </li>
                  <li><i class="bx bx-xs bxs-skull"></i>: One of your crew was eliminated!</li>
                </ul>

                <p>
                  <span class="w-4 h-4 mr-2 rounded die-easy inline-block"></span> Green dice have 3
                  faces with a sword, 1 face with a skull, and 2 faces with a shield.
                </p>
                <p>
                  <span class="w-4 h-4 mr-2 rounded die-intermediate inline-block"></span> Yellow
                  dice have 2 of each kind of face.
                </p>
                <p>
                  <span class="w-4 h-4 mr-2 rounded die-hard inline-block"></span> Red dice have 3
                  faces with a skull, 1 face with a sword, and 2 faces with a shield.
                </p>
                <p>
                  <span class="w-4 h-4 mr-2 rounded die-tricky inline-block"></span> Purple dice
                  have 1 face with quadruple swords, 1 face with triple swords, 1 face with triple
                  skulls, 1 face with double skulls, and 2 faces with a shield.
                </p>

                <p>
                  You can leave a stage at any time, but you will only gain the rewards from that
                  stage once all the obstacles have been eliminated
                </p>
                <p>Good luck!</p>
              </div>
            </template>
          </div>
        </div>
      </Modal>
    </Overlay>
  </Fixed>
</template>

<style lang="postcss">
@media (min-width: 1100px) {
  .leaderboard-modal {
    width: 1000px;
  }
}
</style>
