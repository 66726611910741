import Perk from "./Perk";
import Stage from "./Stage";

class Adventure {
  // name and description for display
  name;
  description;

  // collection of stages for this adventure
  stages = [];
  currentStage = null;

  // the maximum number of rounds that can be played
  startingCrew;

  // the default diceset used for th each stage
  diceset = [];

  // modifiers that might affect the overall counts or other things
  // modifiers are used on the adventure as a whole (different than perks)
  modifiers = [];

  // list of perks available for this adventure
  // perks are used on dice rolls
  perks = [];

  defaultDiceset = [
    "easy",
    "easy",
    "easy",
    "easy",
    "easy",
    "easy",
    "easy",
    "intermediate",
    "intermediate",
    "intermediate",
    "intermediate",
    "intermediate",
    "hard",
    "hard",
    "hard",
  ];

  constructor({ name, description, stages = [], diceset = [], startingCrew = 30, perks = [] }) {
    this.name = name;
    this.description = description;
    this.diceset = diceset.length ? diceset : this.defaultDiceset;
    this.startingCrew = startingCrew;

    this.stages = stages.map((l) => {
      return new Stage({
        diceset: this.diceset,
        ...l,
      });
    });

    if (perks.length) {
      this.perks = perks.map((p) => {
        return new Perk({
          ...p,
        });
      });
    } else {
      this.perks = Perk.defaultPerkset().map((p) => {
        return new Perk({ type: p });
      });
    }

    console.info(this.stages);
  }

  get multiplier() {
    let additionalMultiplier =
      this.stages.reduce((accumulator, rnd) => accumulator + rnd.multiplier, 0) ?? 0;

    return (100 + additionalMultiplier) / 100;
  }

  get rewards() {
    return this.stages.reduce((accumulator, rnd) => accumulator + rnd.reward, 0);
  }

  get totalRewards() {
    return Math.round(this.multiplier * this.rewards);
  }

  get totalCrew() {
    // TO DO, allow modifiers to adjust this
    return this.startingCrew;
  }

  get eliminatedCrew() {
    return Math.min(
      this.startingCrew,
      this.stages.reduce((accumulator, rnd) => accumulator + rnd.skulls, 0)
    );
  }

  get remainingCrew() {
    return this.totalCrew - this.eliminatedCrew;
  }

  get percentWon() {
    return (
      ((this.stages?.filter((l) => l.isWon)?.length ?? 0) / (this.stages?.length ?? 1)) *
      100
    ).toFixed(0);
  }

  get canRoll() {
    return this.currentStage && this.remainingCrew && !this.currentStage.isWon;
  }

  get perksUsed() {
    let perkIdsAvailable = this.perks?.map((p) => p.id) ?? [];
    let perkMapping = {};

    for (let i = 0; i < perkIdsAvailable.length; i++) {
      perkMapping[perkIdsAvailable[i]] = 0;
    }

    this.stages.forEach((stg) => {
      stg.rolls.forEach((roll) => {
        if (roll?.perk?.id) {
          perkMapping[roll.perk.id] = perkMapping[roll.perk.id] ? 1 : perkMapping[roll.perk.id] + 1;
        }
      });
    });

    return perkMapping;
  }

  enterStage(stageOrStageId) {
    if (stageOrStageId instanceof Stage) {
      this.currentStage = stageOrStageId;
    } else {
      this.currentStage = this.stages.find((l) => l.id === stageOrStageId) ?? null;
    }

    return this.currentStage;
  }

  leaveStage() {
    this.currentStage = null;
  }

  roll() {
    if (this.canRoll) {
      return this.currentStage.roll();
    }

    return false;
  }
}

export default Adventure;
