// A roll will act only as a log that something happened
// when the dice are rolled, a roll is created
// when a perk is played, a roll is created with the resulting dice and the perk used
class Roll {
  dice = [];
  perk = null;

  // a stage is how a player plays
  // all the stage data rolls up to an adventure
  constructor({ dice = [], perk = null }) {
    this.dice = dice;
    this.perk = perk;
  }
}

export default Roll;
