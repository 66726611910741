import Die from "./Die";
import { shuffle, random } from "lodash-es";
import Roll from "./Roll";

class Stage {
  id;
  name;
  description;

  // what is the obstacles score for this level
  obstacles = 0;

  // reward && multiplier if stage is won
  rewardNumber;
  multiplierNumber;

  // the starting set of dice for this game
  dice = [];

  // what is actually in the bag
  bag = [];

  // dice that are currently being shown
  table = [];

  // dice that have been pulled from the bag and rolled
  scoredSet = [];

  // all the rolls that occurred
  // using a perk will add a "roll" record with the resulting dice and the perk
  rolls = [];

  // a stage is how a player plays
  // all the stage data rolls up to an adventure
  constructor({
    id,
    obstacles = 1,
    multiplier = 1,
    reward = 100,
    name = null,
    description = null,
    diceset = [],
  }) {
    this.id = id ?? random(1, 1000000, false);
    this.name = name;
    this.description = description;
    this.obstacles = obstacles;
    this.multiplierNumber = multiplier;
    this.rewardNumber = reward;

    this.diceset = diceset?.map((d) => {
      return {
        preset: d,
        id: random(1, 1000000, false),
      };
    });
    // this.dice = this.diceset.map((s) => new Die(s));

    this.fillBag();
  }

  // scored will be table and previously scored dice
  get scored() {
    return [...this.table, ...this.scoredSet];
  }

  get scoredCondensed() {
    let typeMapping = {};

    this.scored.forEach((die) => {
      if (typeMapping?.[die.type]) {
        typeMapping[die.type] = typeMapping[die.type] + die.value;
      } else {
        typeMapping[die.type] = die.value;
      }
    });

    return typeMapping;
  }

  get isWon() {
    return this.swords >= this.obstacles;
  }

  get reward() {
    if (this.isWon) {
      return this.rewardNumber;
    }

    return 0;
  }

  get multiplier() {
    if (this.isWon) {
      return this.multiplierNumber;
    }

    return 0;
  }

  // list of scored dice, but exclude what is on the table (even though those also count for scoring)
  get scoredNoTable() {
    return this?.scoredSet; // ?.filter((d) => !this?.table?.find((t) => t?.id === d?.id)) ?? [];
  }

  // how many dice from scored that have skulls (sum of all values)
  get skulls() {
    return (
      this.scored
        ?.filter((d) => d.type === "skull")
        .map((d) => d?.value)
        .reduce((accumulator, currentValue) => accumulator + currentValue, 0) ?? 0
    );
  }

  get swords() {
    return (
      this.scored
        ?.filter((d) => d.type === "sword")
        .map((d) => d?.value)
        .reduce((accumulator, currentValue) => accumulator + currentValue, 0) ?? 0
    );
  }

  get isStarted() {
    return this.rolls?.length > 0;
  }

  get remainingObstacles() {
    return Math.max(0, this.obstacles - this.swords);
  }

  get diceTypes() {
    let arrayOfPresets = {};
    this.diceset.forEach((d) => {
      arrayOfPresets[d.preset] = arrayOfPresets[d.preset] ? 1 : arrayOfPresets[d.preset] + 1;
    });

    return arrayOfPresets;
  }

  diceInBag(die) {
    return !!this.bag.find((d) => d.id === die.id);
  }

  addRollLog(dice, perk = null) {
    this.rolls = [...this.rolls, new Roll({ dice, perk })];
  }

  roll() {
    if (!this.isWon) {
      let thisroll = this.selectDiceFromBag();
      return true;
    }

    return false;
  }

  // return whatever was in the bag, then refill
  fillBag() {
    let emptyBag = this.bag?.splice(0);

    this.bag = this.diceset.map((s) => new Die(s));

    return emptyBag;
  }

  // pulling from the bag also rolls the dice
  // (once you have pulled you cannot choose not to roll)
  selectDiceFromBag(number = 3) {
    // score any previously on the table that are not shields
    this.scoredSet = [...this.scoredSet, ...this.table?.filter((d) => d?.type !== "shield")];

    // can never pull fewer dice than the table size
    // we wouldn't "know" which dice to replace
    let pullNumber = Math.max(number, this.table?.length);

    // count the number of shields on the table
    let tableShields = this.table?.filter((d) => d.type === "shield") ?? [];

    // get the number of additional dice needed from the bag
    // if number is lower, then ensure pulling 0 from bag
    let numNeededFromBag = Math.max(pullNumber - tableShields?.length, 0);

    let restOfbag = [];
    // if there aren't enough dice in the bag for "the rest"
    if (this.bag?.length < numNeededFromBag) {
      // pull out the dice from the bag and refill bag
      restOfbag = [...this.fillBag()];

      // reassign this number because now I just need to know how many more to pull
      numNeededFromBag = pullNumber - [...tableShields, ...restOfbag].length;
    }

    // mix up the bag
    this.bag = shuffle(this.bag);

    // create a new array for the size new table (will match the number)
    let newTable = [];
    for (let i = 0; i < pullNumber; i++) {
      newTable[i] = null;
    }

    // below code inserts new table dice in place
    tableShields.forEach((ts) => {
      let ind = this.table.findIndex((td) => ts.id == td.id);
      newTable[ind] = ts;
    });
    let bagpulls = [...restOfbag, ...this.bag.slice(0, numNeededFromBag)];
    let currentBagPullInd = 0;
    newTable = newTable.map((nt) => {
      if (nt !== null) {
        return nt;
      }
      let bagpull = bagpulls.slice(currentBagPullInd, currentBagPullInd + 1);
      currentBagPullInd++;
      return bagpull[0];
    });

    this.table = [...newTable];
    this.bag = this.bag.slice(numNeededFromBag);
    this.table.forEach((d) => d.roll());

    // only score non zeros
    // this.scored = [...this.scored, ...this.table?.filter((d) => d?.value !== 0)];

    // shoudln't matter if bag refills, even if stage is complete
    if (this.bag?.length < 1) {
      this.fillBag();
    }

    // finally, add a log entry for the roll
    this.addRollLog([...this.table]);

    return this.table;
  }
}

export default Stage;
